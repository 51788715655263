import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-prestige-classes"></a><h2>Epic Prestige Classes
    </h2>
    <a id="cosmic-descryer"></a><h3>COSMIC DESCRYER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d4.
    <a id="cosmic-descryer-requirements"></a><h6>Requirements</h6>
To qualify to become a cosmic descryer, a character must
fulfill all the following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(the planes) 24 ranks. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Feats:</span> <a href="featsAll.html#spell-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spell
Focus</a> (Conjuration). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Epic Feats:</span> <a href="epicFeats.html#energy-resistance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Energy Resistance</a>. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> Ability to cast gate plus any planar
ally or planar binding spell.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> Must have
previously traveled to any other plane of existence. </p>
    <p style={{
      "fontWeight": "bold"
    }}>Class Skills</p>
    <p>The cosmic descryer&#8217;s class skills (and the key ability for
each skill) are <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a> (Cha), <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a>
(Con), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a> (Cha), <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(arcana) (Int), Knowledge (religion) (Int), Knowledge (the planes)
(Int), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a> (Wis), <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense
Motive</a> (Wis), and <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a> (Int). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
2 + Int modifier. </p>
    <a id="table-the-cosmic-descryer"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Cosmic Descryer </span>
    <table style={{
      "width": "662px",
      "height": "258px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "50px"
          }}>Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
          <td style={{
            "verticalAlign": "top",
            "width": "160px"
          }}><span style={{
              "fontWeight": "bold"
            }}>Spells per Day</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "50px"
          }}>1st
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Superior
planar summoning (+4 HD)
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "160px"
          }}>
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "50px"
          }}>2nd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Naturalization
(1 plane)</td>
          <td style={{
            "verticalAlign": "top",
            "width": "160px"
          }}>+1 level of
existing class</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "50px"
          }}>3rd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Enduring
gate (1 day)</td>
          <td style={{
            "verticalAlign": "top",
            "width": "160px"
          }}>
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "50px"
          }}>4th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Naturalization
(2 planes)</td>
          <td style={{
            "verticalAlign": "top",
            "width": "160px"
          }}>+1 level of
existing class</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "50px"
          }}>5th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus
feat, superior planar summoning (+8 HD) </td>
          <td style={{
            "verticalAlign": "top",
            "width": "160px"
          }}>
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "50px"
          }}>6th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Naturalization
(3 planes), enduring gate (2 days)</td>
          <td style={{
            "verticalAlign": "top",
            "width": "160px"
          }}>+1 level of
existing class</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "50px"
          }}>7th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Cosmic
connection 1/day</td>
          <td style={{
            "verticalAlign": "top",
            "width": "160px"
          }}>
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "50px"
          }}>8th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Naturalization
(4 planes)</td>
          <td style={{
            "verticalAlign": "top",
            "width": "160px"
          }}>+1 level of
existing class</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "50px"
          }}>9th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Superior
planar summoning (+12 HD), enduring gate (3 days)
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "160px"
          }}>
          </td>
        </tr>
        <tr>
          <td className="last-row" style={{
            "width": "50px"
          }}>10th
          </td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Naturalization (5 planes), bonus feat</td>
          <td style={{
            "verticalAlign": "top",
            "width": "160px"
          }}>+1 level of
existing class</td>
        </tr>
      </tbody>
    </table>
    <a id="cosmic-descryer-class-features"></a><h6>Class Features</h6>
The following are class features of the cosmic descryer prestige class.
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
A cosmic descryer gains no proficiency with any weapons, armor, or
shields. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells per Day/Spells Known:</span>
At every other cosmic descryer level, the character gains new spells
per day (and spells known, if applicable) as if he or she had also
gained a level in a spellcasting class to which he or she belonged
before adding the prestige class level. If already an epic spellcaster,
the character gains only the benefit noted under the Spells entry for
that epic class. He or she does not, however, gain any other benefit a
character of that class would have gained. If the character had more
than one spellcasting class before becoming a cosmic descryer, the
player must decide to which class to add the new level for the purpose
of determining spells per day. </p>
    <a id="cosmic-descryer-superior-planar-summoning"></a><p><span style={{
        "fontWeight": "bold"
      }}>Superior Planar Summoning
(Ex):</span> Beginning at 1st level, the cosmic descryer can increase
the power of any of the following spells-elemental swarm, gate, greater
planar ally, greater planar binding, summon monster IX, or summon
nature&#8217;s ally IX-to affect or summon outsiders of 4 Hit Dice higher
than the spell&#8217;s normal limit or conjure creatures with 4 Hit Dice of
advancement. Every four levels thereafter, the cosmic descryer can
increase the number of extra Hit Dice by 4. </p>
    <a id="cosmic-descryer-naturalization"></a><p><span style={{
        "fontWeight": "bold"
      }}>Naturalization (Ex):</span>
Starting at 2nd level, the cosmic descryer develops a natural affinity
for one plane that he or she has visited, becoming resistant to any
spells and spell-like effects that would normally affect any creature
not native to that plane. Every two levels thereafter, the cosmic
descryer gains naturalization to one additional plane. </p>
    <a id="cosmic-descryer-ennduring-gate"></a><p><span style={{
        "fontWeight": "bold"
      }}>Enduring Gate (Su):</span>
Starting at 3rd level, as a full-round action, the cosmic descryer can
make any casting of the gate spell remain for a full day, rather than
disappearing after 1 round per caster level. Every three levels
thereafter, the cosmic descryer can increase the duration of gate by
one additional day. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feat (Ex)</span>: The
cosmic descryer gains a bonus feat at 5th level and an additional bonus
feat every five levels thereafter. These bonus feats must be selected
from the following list: Augmented Alchemy, Automatic Quicken Spell,
Automatic Silent Spell, Automatic Still Spell, Combat Casting, Craft
Epic Magic Arms and Armor, Craft Epic Rod, Craft Epic Staff, Craft Epic
Wondrous Item, Efficient Item Creation, Enhance Spell, Epic Spell
Focus, Epic Spell Penetration, Epic Spellcasting, Familiar Spell, Forge
Epic Ring, Ignore Material Components, Improved Combat Casting,
Improved Heighten Spell, Improved Metamagic, Improved Spell Capacity,
Intensify Spell, Multispell, Permanent Emanation, Scribe Epic Scroll,
Spell Focus, Spell Knowledge, Spell Mastery, Spell Opportunity, Spell
Penetration, Spell Stowaway, Spontaneous Spell, Tenacious Magic. </p>
    <a id="cosmic-descryer-cosmic-connection"></a><p><span style={{
        "fontWeight": "bold"
      }}>Cosmic Connection (Su):</span>
At 7th level, the cosmic descryer may join with the massive energy of
the multiverse once per day, plus one additional time per day every
five levels thereafter. The cosmic descryer can remain connected for a
number of minutes equal to his or her class level. While connected, the
character is immune to critical hits, is a native on any plane he or
she visits, and can use dimension door at will as a 20th-level caster.
The cosmic descryer can draw off excess energy from the multiverse
itself to increase his or her effective caster level or enhance any
attack roll, saving throw, skill check, or ability check. Drawing off
excess energy from the multiverse is dangerous, and it deals the cosmic
descryer 5 points of damage for each +1 bonus applied to a single roll
or +1 caster level on a single spell.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      